const premises = [
  "technology and nature have become inseparable",
  "humans have developed the ability to communicate with animals",
  "time travel is an everyday occurrence",
  "dreams can be shared and explored collectively",
  "emotions can be transferred between people",
  "memories can be bought and sold",
  "gravity is no longer constant",
  "plants have developed consciousness",
  "humans can photosynthesize",
  "books can read their readers' minds",
  "cities float in the sky",
  "music has tangible physical properties",
  "shadows have gained sentience",
  "water has become a rare and precious resource",
  "everyone can see one year into their own future",
];

export const getRandomPremise = () => {
  const randomIndex = Math.floor(Math.random() * premises.length);
  return premises[randomIndex];
};