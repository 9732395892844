import { streamedApiCall, regularApiCall } from "../utils/api_utils";

const API_VER = "api/v1/";
const API_ENDPOINTS = {
  NEW_PARAGRAPHS: API_VER + "paragraphs/new",
  EDIT_PARAGRAPH: API_VER + "paragraphs/edit",
  REWRITE_PARAGRAPHS: API_VER + "paragraphs/rewrite",
  INSERTED_PARAGRAPHS: API_VER + "paragraphs/inserted",
  SECTION_SUMMARY: API_VER + "paragraphs/section/summary",
};

export const getNewParagraphs = async (
  instruction,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = false
) => {
  return new Promise((resolve, reject) => {
    console.log("getNewParagraphs", isNsfw);
    let newParagraphs = [];
    let currentParagraph = "";

    const sendUpdate = () => {
      onProgress([...newParagraphs, currentParagraph.trim()]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(newParagraphs);
        } else {
          if (data.chunk.includes("\\n\\n")) {
            let splits = data.chunk.split("\\n\\n");
            currentParagraph += splits[0] + " ";
            newParagraphs.push(currentParagraph.trim());
            currentParagraph = splits[1];
            sendUpdate();
          } else {
            currentParagraph += data.chunk + " ";
            sendUpdate();
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.NEW_PARAGRAPHS}`,
      "POST",
      {
        context: context,
        instruction,
        count,
        isNsfw,
        stream,
      },
      onChunk,
      onError
    );
  });
};

export const getEditedParagraph = async (sentencesWithActions, onProgress) => {
  return new Promise((resolve, reject) => {
    let newSentences = [];

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(newSentences);
        } else {
          let parsedChunk = JSON.parse(data.chunk);
          console.log(parsedChunk);
          newSentences = parsedChunk;
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.EDIT_PARAGRAPH}`,
      "POST",
      {
        context: {
          synopsis:
            "Frustrated by countless rejections, Arjun embarks on an online job search journey that forces him to confront his insecurities and aspirations.",
        },
        sentencesWithActions,
        isNsfw: false,
        stream: true,
      },
      onChunk,
      onError
    );
  });
};

const cleanJsonString = (jsonString) => {
  jsonString = jsonString.replace(/^```json\s*/m, "");
  jsonString = jsonString.replace(/\s*```$/m, "");
  return jsonString.trim();
};

export const getRewriteParagraphs = async (
  instruction,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = false
) => {
  return new Promise((resolve, reject) => {
    console.log("getRewriteParagraphs");
    let newParagraphs = [{ sentences: [] }];
    let currentIndex = 0;

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          //if the last paragraph is empty, remove it
          if (newParagraphs[currentIndex].sentences.length === 0) {
            newParagraphs.pop();
          }
          resolve(newParagraphs);
        } else {
          const cleanedChunk = cleanJsonString(data.chunk);
          let parsedChunk = JSON.parse(cleanedChunk);
          if (parsedChunk.action !== "paragraph_break") {
            newParagraphs[currentIndex].sentences.push(parsedChunk);
            onProgress(newParagraphs);
          } else {
            currentIndex++;
            newParagraphs.push({ sentences: [] });
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.REWRITE_PARAGRAPHS}`,
      "POST",
      {
        context: context,
        instruction,
        count,
        isNsfw: false,
        stream: true,
      },
      onChunk,
      onError
    );
  });
};

export const getInsertedParagraphs = async (
  instruction,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = false
) => {
  return new Promise((resolve, reject) => {
    let newParagraphs = [];
    let currentParagraph = "";

    const sendUpdate = () => {
      onProgress([...newParagraphs, currentParagraph.trim()]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(newParagraphs);
        } else {
          if (data.chunk.includes("\\n\\n")) {
            let splits = data.chunk.split("\\n\\n");
            currentParagraph += splits[0] + " ";
            newParagraphs.push(currentParagraph.trim());
            currentParagraph = splits[1];
            sendUpdate();
          } else {
            currentParagraph += data.chunk + " ";
            sendUpdate();
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.INSERTED_PARAGRAPHS}`,
      "POST",
      {
        context,
        instruction,
        count,
        isNsfw,
        stream,
      },
      onChunk,
      onError
    );
  });
};

export const getSectionSummary = (paragraphs, prev_sections) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await regularApiCall(
        `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.SECTION_SUMMARY}`,
        "POST",
        {
          paragraphs,
          prev_sections,
          stream: false,
        }
      );
      if (response.error) {
        reject(response.error);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
