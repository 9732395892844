import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Paragraph from "../components/Paragraph";
import ContentGenerator from "../components/ContentGenerator";
import Premise from "../components/Premise";
import StoryTitle from "../components/StoryTitle";
import { useStory } from "../context/StoryContext";
import { getRandomPremise } from "../utils/premiseProvider";
import { getNewParagraphs } from "../api/paragraphs";
import { generateTitle } from "../api/stories";
import {
  FiRefreshCw,
  FiChevronDown,
  FiChevronUp,
  FiList,
  FiLoader,
  FiChevronsDown,
} from "react-icons/fi";
import {
  saveStoryToLocalStorage,
  loadLastStoryFromLocalStorage,
  loadStoryFromTitle,
} from "../utils/storageUtils";
import StoryActionButtons from "../components/StoryActionButtons";
import SectionBreak from "../components/SectionBreak";

const AIStoryGenerator = () => {
  const location = useLocation();
  const [activeParagraph, setActiveParagraph] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [title, setTitle] = useState("AI Story Generator");
  const { state, dispatch } = useStory();
  const [showSectionBreakButtons, setShowSectionBreakButtons] = useState(false);
  const [isContinueStory, setIsContinueStory] = useState(false);

  useEffect(() => {
    setTitle("AI Story Generator");
    const searchParams = new URLSearchParams(location.search);
    const isNew = searchParams.get("new") === "true";
    if (location.state && location.state.story_title) {
      loadStory(location.state.story_title);
    } else if (isNew) {
      resetStory();
    } else {
      loadFromLocalStorage();
    }
  }, [location]);

  useEffect(() => {
    // Save to local storage whenever the story updates
    if (state.paragraphs.length > 0 && state.title) {
      console.log("Saving story to local storage ", state.paragraphs.length);
      saveStoryToLocalStorage({
        title: state.title,
        premise: state.premise,
        paragraphs: state.paragraphs,
      });
    }
    if (state.title) {
      setTitle(state.title);
    } else {
      setTitle("AI Story Generator");
    }
  }, [activeParagraph, state.paragraphs, state.premise, state.title]);

  const loadStory = (story_title) => {
    const story = loadStoryFromTitle(story_title);
    console.log("Loaded story ", story);
    if (story && story.paragraphs) {
      dispatch({ type: "SET_TITLE", payload: story.title });
      dispatch({ type: "SET_PREMISE", payload: story.premise });
      dispatch({ type: "SET_PARAGRAPHS", payload: story.paragraphs });
    }
  };

  const loadFromLocalStorage = () => {
    const lastStory = loadLastStoryFromLocalStorage();
    if (lastStory) {
      console.log(
        "Loading story from local storage ",
        lastStory.paragraphs.length
      );
      dispatch({ type: "SET_TITLE", payload: lastStory.title });
      dispatch({ type: "SET_PREMISE", payload: lastStory.premise });
      dispatch({ type: "SET_PARAGRAPHS", payload: lastStory.paragraphs });
    } else {
      generateNewPremise();
    }
  };

  const generateNewPremise = () => {
    const premise = getRandomPremise();
    dispatch({ type: "SET_PREMISE", payload: premise });
  };

  const onGenerateProgress = (progress) => {
    dispatch({ type: "SET_PARAGRAPHS", payload: progress });
  };

  const generateParagraphs = async () => {
    setIsGenerating(true);
    const context = {
      premise: state.premise,
    };
    const instruction = "Write a fresh story";
    try {
      const result = await getNewParagraphs(
        instruction,
        3,
        context,
        onGenerateProgress
      );
      setActiveParagraph(null);
      await generateStoryTitle();
    } catch (error) {
      console.error("Error generating paragraphs:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsGenerating(false);
    }
  };

  const generateStoryTitle = async () => {
    setIsGeneratingTitle(true);
    try {
      const context = {
        paragraphs: state.paragraphs.slice(0, 2).join(" "),
        premise: state.premise,
      };
      const generatedTitle = await generateTitle(context);
      console.log("Generated title:", generatedTitle);
      setTitle(generatedTitle);
      dispatch({ type: "SET_TITLE", payload: generatedTitle });
    } catch (error) {
      console.error("Error generating title:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsGeneratingTitle(false);
    }
  };

  const handleContentFinalize = (newParagraphs) => {
    dispatch({ type: "ADD_PARAGRAPHS", payload: newParagraphs });
    setActiveParagraph(null);
  };

  const resetStory = () => {
    dispatch({ type: "RESET_STORY" });
    generateNewPremise();
    setActiveParagraph(null);
    setTitle("AI Story Generator");
  };

  const handlePremiseUpdate = (newPremise) => {
    dispatch({ type: "SET_PREMISE", payload: newPremise });
  };

  const handleRemoveSectionBreak = (index) => {
    console.log("Removing section break at index:", index);
    dispatch({
      type: "REMOVE_SECTION_BREAK",
      payload: { index },
    });
  };

  const toggleSectionCollapse = useCallback(
    (sectionIndex) => {
      dispatch({ type: "TOGGLE_SECTION_COLLAPSE", payload: sectionIndex });
    },
    [dispatch]
  );

  const isParagraphCollapsed = useCallback(
    (paragraphIndex) => {
      if (!state.collapsedSections) return false;
      const nextSectionBreak = state.paragraphs
        .slice(paragraphIndex)
        .findIndex((item) => item.type === "section_break");

      const nextSectionBreakIndex =
        nextSectionBreak !== -1 ? paragraphIndex + nextSectionBreak : -1;

      return state.collapsedSections.some(
        (sectionIndex) =>
          nextSectionBreakIndex !== -1 && nextSectionBreakIndex === sectionIndex
      );
    },
    [state.collapsedSections, state.paragraphs]
  );

  const toggleAllSections = useCallback(() => {
    dispatch({ type: "TOGGLE_ALL_SECTIONS" });
    scrollToTop();
  }, [dispatch]);

  const toggleSectionBreakButtons = useCallback(() => {
    setShowSectionBreakButtons((prev) => !prev);
  }, []);

  const toggleContinueStory = useCallback(() => {
    scrollToBottom();
    setIsContinueStory((prev) => !prev);
  }, []);

  const scrollToBottom = () => {
    console.log("Scrolling to bottom");
    const paragraphs = document.querySelectorAll(
      ".border.border-amber-100 > div"
    );
    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      lastParagraph.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      console.error("No paragraphs found");
    }
  };

  const scrollToTop = () => {
    const paragraphs = document.querySelectorAll(
      ".border.border-amber-100 > div"
    );
    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[0];
      lastParagraph.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      console.error("No paragraphs found");
    }
  };

  const handleParagraphAction = useCallback(
    (action) => {
      if (activeParagraph !== null) {
        // Find the active paragraph component and call its handleModeChange function
        const paragraphComponent = document.querySelector(
          `[data-paragraph-index="${activeParagraph}"]`
        );
        if (paragraphComponent) {
          const handleModeChange =
            paragraphComponent.__react_internal_instance$.return.stateNode
              .handleModeChange;
          handleModeChange(action);
        }
      }
      if (action === null) {
        setActiveParagraph(null);
      }
    },
    [activeParagraph]
  );

  return (
    <>
      <Helmet>
        <title>AI Story Generator | Create Unique Stories with AI</title>
        <meta
          name="description"
          content="Use our AI Story Generator to create unique, engaging stories with artificial intelligence. Generate creative premises and expand your narrative effortlessly. Rewrite your story with AI."
        />
        <meta
          name="keywords"
          content="AI story generator, artificial intelligence, creative writing, story creation, rewrite story"
        />
        <link
          rel="canonical"
          href="https://letmewriteforyou.xyz/ai-story-generator"
        />
      </Helmet>
      <div className="h-full flex flex-col bg-gray-100">
        <div className="flex-grow overflow-auto p-2 sm:p-4 pb-16 sm:pb-4">
          <div className="flex flex-col mb-4">
            <div className="hidden sm:block mb-4">
              <StoryActionButtons resetStory={resetStory} />
            </div>

            <StoryTitle
              title={title}
              isGeneratingTitle={isGeneratingTitle}
              onGenerateTitle={generateStoryTitle}
            />

            <Premise premise={state.premise} onUpdate={handlePremiseUpdate} />

            {state.paragraphs.length === 0 && !isGenerating && (
              <button
                onClick={generateParagraphs}
                className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg text-sm transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-md"
              >
                Generate Story
              </button>
            )}
          </div>

          <div className="border border-amber-100 rounded-lg shadow-sm p-2 sm:p-4">
            {state.paragraphs.map((item, index) =>
              item.type === "section_break" ? (
                <SectionBreak
                  key={`section-break-${index}`}
                  index={index}
                  onRemove={() => handleRemoveSectionBreak(index)}
                  summary={item.summary}
                  showSectionBreakButtons={showSectionBreakButtons}
                  isCollapsed={
                    state.collapsedSections
                      ? state.collapsedSections.includes(index)
                      : false
                  }
                  onToggleCollapse={() => toggleSectionCollapse(index)}
                />
              ) : (
                <Paragraph
                  key={index}
                  content={item}
                  index={index}
                  isActive={activeParagraph === index}
                  setActiveParagraph={setActiveParagraph}
                  isCollapsed={isParagraphCollapsed(index)}
                  showSectionBreakButtons={showSectionBreakButtons}
                />
              )
            )}
            {isGenerating && (
              <div className="flex items-center justify-center py-4">
                <FiLoader
                  className="animate-spin text-blue-500 mr-2"
                  size={24}
                />
                <p className="text-gray-600">Generating more content...</p>
              </div>
            )}
            {state.paragraphs.length === 0 && !isGenerating && (
              <div className="flex items-center justify-center h-64 text-gray-500 text-lg">
                Your generated story will appear here
              </div>
            )}
          </div>
        </div>

        <div className="border-t border-gray-200 p-0 sm:p-2 mb-14 sm:mb-0">
          <div className="hidden sm:block">
            <ContentGenerator
              onFinalize={handleContentFinalize}
              mode="new_paragraphs"
            />
          </div>
          {isContinueStory && activeParagraph === null && (
            <div className="sm:hidden">
              <ContentGenerator
                onFinalize={handleContentFinalize}
                mode="new_paragraphs"
              />
            </div>
          )}
        </div>

        <div className="sm:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-2">
          <StoryActionButtons
            activeParagraph={activeParagraph}
            resetStory={resetStory}
            toggleAllSections={toggleAllSections}
            areAllSectionsCollapsed={state.areAllSectionsCollapsed}
            toggleSectionBreakButtons={toggleSectionBreakButtons}
            showSectionBreakButtons={showSectionBreakButtons}
            isContinueStory={isContinueStory}
            toggleContinueStory={toggleContinueStory}
            onParagraphAction={handleParagraphAction}
          />
        </div>
      </div>
    </>
  );
};

export default AIStoryGenerator;
