import React from "react";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";

const StoryList = ({ stories, type, onDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {stories.map((story) => (
        <div
          key={story.id}
          className="bg-white rounded-lg shadow-md p-4 relative"
        >
          <Link
            to={{
              pathname: "/ai-story-generator",
              state: { story_title: story.title },
            }}
            className="block"
          >
            <h3 className="text-lg font-semibold mb-2">{story.title}</h3>
            <p className="text-gray-600 mb-2">{story.summary}</p>
            <p className="text-sm text-gray-500">
              Created: {new Date(story.createdAt).toLocaleDateString()}
            </p>
          </Link>
          {type === "private" && onDelete && (
            <button
              onClick={() => onDelete(story.id)}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              title="Delete story"
            >
              <FiTrash2 size={20} />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default StoryList;
