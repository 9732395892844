import React, { useContext, useState, useEffect } from "react";
import {
  getNewParagraphs,
  getRewriteParagraphs,
  getInsertedParagraphs,
} from "../api/paragraphs";
import RewrittenParagraph from "./RewrittenParagraph";
import ClearableTextarea from "./ClearableTextarea";
import VerticalSlider from "./VerticalSlider";
import {
  FaCheck,
  FaTimes,
  FaCog,
  FaPaperPlane,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { useStory } from "../context/StoryContext";

const ContentGenerator = ({
  onFinalize,
  mode,
  context = {},
  title = "Generate new paragraphs",
  onGeneratedContent,
}) => {
  const { state } = useStory();
  const [instruction, setInstruction] = useState("");
  const [draftContent, setDraftContent] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [error, setError] = useState(null);
  const [showSlider, setShowSlider] = useState(false);
  const [showInputBar, setShowInputBar] = useState(true);
  const [isNSFW, setIsNSFW] = useState(false);

  useEffect(() => {
    setDraftContent(null);
    setCount(1);
    setError(null);
    console.log("Mode:", mode);
  }, [mode]);

  const onProgress = (content) => {
    setInProgress(true);
    setDraftContent(content);
    if (mode == "rewrite") {
      onGeneratedContent(content);
    }
  };

  const handleSubmit = async (e) => {
    console.log("Submit");
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setDraftContent(null);
    try {
      if (mode === "insert") {
        console.log(context);
        let resolved = await getInsertedParagraphs(
          instruction,
          count,
          context,
          onProgress,
          true,
          isNSFW
        );
        if (resolved) {
          setInProgress(false);
        }
      } else if (mode === "rewrite") {
        const result = await getRewriteParagraphs(
          instruction,
          count,
          context,
          onProgress
        );
        setDraftContent(result);
        onGeneratedContent(result);
      } else if (mode === "new_paragraphs") {
        const paragraphs = state.paragraphs.filter(
          (item) => item.type !== "section_break"
        );
        const previous_paragraphs = paragraphs.slice(-3).join("\n\n");
        let prev_sections = state.paragraphs
          .filter((item) => item.type === "section_break")
          .map((item) => item.summary);
        prev_sections = prev_sections.join("\n\n");

        const newContext = {
          premise: state.premise,
          previous_paragraphs: previous_paragraphs,
          previous_sections: prev_sections,
        };
        // console.log(newContext);
        let resolved = await getNewParagraphs(
          instruction,
          count,
          newContext,
          onProgress,
          true,
          isNSFW
        );
        if (resolved) {
          setInProgress(false);
        }
      }
    } catch (error) {
      console.error("Error generating content:", error);
      setError(error.message || "An error occurred while generating content");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinalize = () => {
    onFinalize(draftContent);
    setInstruction("");
    setDraftContent(null);
    setCount(1);
    if (mode === "new_paragraphs") {
      setShowInputBar(true);
    }
  };

  const handleCancel = () => {
    setDraftContent(null);
  };

  const renderDraftContent = () => {
    if (!draftContent) return null;

    return (
      <div className="mt-4 bg-amber-50 rounded-lg p-4 shadow-md border border-yellow-200">
        <div className="max-h-60 overflow-y-auto rounded-md p-3 shadow-inner">
          {mode === "rewrite"
            ? draftContent.map((paragraph, index) => (
                <RewrittenParagraph
                  key={index}
                  sentences={paragraph.sentences}
                />
              ))
            : Array.isArray(draftContent) &&
              draftContent.map((paragraph, index) => (
                <p key={index} className="mb-2 text-sm text-gray-800">
                  {paragraph}
                </p>
              ))}
        </div>
        {!inProgress && (
          <div className="flex flex-col sm:flex-row gap-2 mt-4">
            <button
              onClick={handleFinalize}
              className="flex-1 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaCheck className="mr-2" /> Accept
            </button>
            <button
              onClick={handleCancel}
              className="flex-1 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaTimes className="mr-2" /> Reject
            </button>
          </div>
        )}
      </div>
    );
  };

  const toggleNSFW = () => {
    setIsNSFW(!isNSFW);
  };

  return (
    <div className="bg-gradient-to-r from-yellow-50 to-yellow-100 px-2 rounded-lg shadow-md">
      {showInputBar && (
        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => setShowSlider(!showSlider)}
              className="p-2 text-yellow-600 hover:text-yellow-700 transition-colors duration-200 bg-white rounded-full shadow-sm"
              aria-label="Settings"
            >
              <FaCog />
            </button>
            <div className="flex-grow relative">
              <ClearableTextarea
                value={instruction}
                onChange={setInstruction}
                placeholder={`${title}`}
                rows={2}
                className="w-full p-3 text-sm border border-yellow-300 rounded-md focus:ring-2 focus:ring-yellow-400 focus:border-transparent pr-12"
              />
              <button
                type="submit"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 flex items-center justify-center"
                disabled={isLoading}
              >
                <FaPaperPlane className="w-4 h-4 sm:mr-1" />
                <span className="hidden sm:inline">
                  {isLoading
                    ? "Processing..."
                    : mode === "rewrite"
                    ? "Rewrite"
                    : "Generate"}
                </span>
              </button>
            </div>
          </div>
          {showSlider && (
            <div className="mt-2 flex items-center space-x-4">
              <div>
                <VerticalSlider value={count} onChange={setCount} />
              </div>
              <button
                type="button"
                onClick={toggleNSFW}
                className="flex items-center space-x-2 text-sm"
              >
                <span>NSFW</span>
                {isNSFW ? (
                  <FaToggleOn className="text-yellow-500 text-xl" />
                ) : (
                  <FaToggleOff className="text-gray-400 text-xl" />
                )}
              </button>
            </div>
          )}
        </form>
      )}

      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
          <span className="ml-2 text-sm text-gray-600">
            Generating content...
          </span>
        </div>
      )}

      {error && (
        <div
          className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-md text-sm"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span>{error}</span>
        </div>
      )}

      {(mode === "insert" || mode === "new_paragraphs") && renderDraftContent()}
    </div>
  );
};

export default ContentGenerator;
