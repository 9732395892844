import React from "react";
import { FaLightbulb } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";

const StoryTitle = ({ title, isGeneratingTitle, onGenerateTitle }) => {
  return (
    <div className="flex items-center justify-between w-full mb-2">
      <h1 className="text-xl sm:text-2xl font-bold text-gray-800 truncate flex-grow">
        {isGeneratingTitle ? (
          <span className="flex items-center">
            <FiLoader className="animate-spin mr-2" size={20} />
            Generating title...
          </span>
        ) : (
          title
        )}
      </h1>
      <button
        onClick={onGenerateTitle}
        className="text-amber-500 hover:text-amber-600 transition duration-300 ease-in-out transform hover:scale-110 focus:outline-none ml-2"
        aria-label="Refresh Title"
      >
        <FaLightbulb size={20} />
      </button>
    </div>
  );
};

export default StoryTitle;
