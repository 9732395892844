export const saveStoryToLocalStorage = (story) => {
  // Add createdAt timestamp if it doesn't exist
  const storyWithTimestamp = {
    ...story,
    createdAt: story.createdAt || new Date().toISOString(),
  };

  let stories = JSON.parse(localStorage.getItem("stories")) || [];
  const title = storyWithTimestamp.title.replace(/\s+/g, "_").toLowerCase();
  const storyToSave = { ...storyWithTimestamp, id: title };

  const storyIndex = stories.findIndex((s) => s.id === title);
  if (storyIndex !== -1) {
    stories[storyIndex] = storyToSave;
  } else {
    stories.push(storyToSave);
  }

  localStorage.setItem("stories", JSON.stringify(stories));
  //   console.log("Story saved to local storage:", storyToSave);
};

export const loadLastStoryFromLocalStorage = () => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  return stories.length > 0 ? stories[stories.length - 1] : null;
};

export const loadStoryFromTitle = (title) => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  return stories.find((s) => s.title === title);
};

export const loadAllStoriesFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("stories")) || [];
};

export const clearAllStoriesFromLocalStorage = () => {
  localStorage.removeItem("stories");
};

export const deleteStoryFromLocalStorage = (storyId) => {
  const stories = loadAllStoriesFromLocalStorage();
  const updatedStories = stories.filter((story) => story.id !== storyId);
  localStorage.setItem("stories", JSON.stringify(updatedStories));
};
