import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FiList,
  FiRefreshCw,
  FiSettings,
  FiFeather,
  FiEdit,
  FiShare,
  FiX,
  FiMinimize2,
  FiMaximize2,
  FiPlusSquare,
  FiType,
} from "react-icons/fi";
import { FaEdit, FaSync, FaPlus, FaTrash } from "react-icons/fa";

const StoryActionButtons = ({
  activeParagraph,
  resetStory,
  toggleAllSections,
  areAllSectionsCollapsed,
  toggleSectionBreakButtons,
  showSectionBreakButtons,
  isContinueStory,
  toggleContinueStory,
  onParagraphAction,
}) => {
  const [openSlider, setOpenSlider] = useState(null);
  const [activeSliderOption, setActiveSliderOption] = useState(null);

  useEffect(() => {
    if (activeParagraph !== null && activeParagraph !== undefined) {
      setOpenSlider("Paragraph");
    } else {
      setOpenSlider(null);
    }
  }, [activeParagraph]);

  const toggleSlider = (slider) => {
    setOpenSlider(openSlider === slider ? null : slider);
  };

  const renderSliderContent = (options) => (
    <div className="flex space-x-1 overflow-x-auto">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            option.onClick(e);
          }}
          className={`py-1 px-2 rounded-full whitespace-nowrap transition-all duration-200 flex items-center text-xs ${
            option.isActive
              ? "bg-yellow-500 text-white"
              : "bg-yellow-100 text-yellow-700"
          }`}
        >
          {option.icon}
          {option.isActive && option.activeLabel && (
            <span className="ml-1">{option.activeLabel}</span>
          )}
        </button>
      ))}
    </div>
  );

  const sliderOptions = {
    Settings: [
      {
        label: null,
        icon: areAllSectionsCollapsed ? (
          <FiMaximize2 size={18} />
        ) : (
          <FiMinimize2 size={18} />
        ),
        onClick: (e) => {
          e.stopPropagation();
          toggleAllSections();
        },
        isActive: areAllSectionsCollapsed,
      },
      {
        label: null,
        icon: <FiPlusSquare size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          toggleSectionBreakButtons();
        },
        isActive: showSectionBreakButtons,
      },
    ],
    Edit: [
      {
        label: null,
        icon: isContinueStory ? <FiFeather size={18} /> : <FiType size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          toggleContinueStory();
        },
        isActive: isContinueStory,
      },
    ],
    Paragraph: [
      {
        label: null,
        icon: <FaEdit size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          setActiveSliderOption("edit");
          onParagraphAction("edit");
        },
        activeLabel: "Edit",
        isActive: activeSliderOption === "edit",
      },
      {
        label: null,
        icon: <FaSync size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          setActiveSliderOption("rewrite");
          onParagraphAction("rewrite");
        },
        isActive: activeSliderOption === "rewrite",
        activeLabel: "Rewrite",
      },
      {
        label: null,
        icon: <FaPlus size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          setActiveSliderOption("insert");
          onParagraphAction("insert");
        },
        activeLabel: "Insert",
        isActive: activeSliderOption === "insert",
      },
      {
        label: null,
        icon: <FaTrash size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          setActiveSliderOption("delete");
          onParagraphAction("delete");
        },
        isActive: activeSliderOption === "delete",
      },
    ],
  };

  const renderToggleButton = (slider) => {
    if (slider === "Paragraph" && activeParagraph === null) {
      return null;
    }

    const Icon =
      slider === "Settings"
        ? FiSettings
        : slider === "Edit"
        ? FiEdit
        : slider === "Paragraph"
        ? FiType
        : FiShare;
    return (
      <button
        key={slider}
        onClick={(e) => {
          e.stopPropagation();
          toggleSlider(slider);
        }}
        className={`flex items-center justify-center transition-all duration-300 ease-in-out p-1 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 rounded-full ${
          openSlider === slider
            ? "bg-gray-500 text-white w-8 h-8"
            : "bg-white text-gray-600 hover:bg-gray-50 focus:ring-gray-300 w-6 h-6"
        }`}
        aria-label={slider}
      >
        <Icon size={openSlider === slider ? 18 : 14} />
      </button>
    );
  };

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      <div className="flex items-center justify-between w-full space-x-2">
        <Link
          to="/stories"
          className="flex items-center justify-center bg-white text-blue-500 hover:bg-blue-50 transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          aria-label="View Stories"
        >
          <FiList size={18} />
          <span className="ml-1 text-xs hidden sm:inline">Stories</span>
        </Link>

        <div className="sm:hidden flex items-center space-x-1 flex-grow justify-center">
          {openSlider ? (
            <>
              {renderToggleButton(openSlider)}
              {renderSliderContent(sliderOptions[openSlider])}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenSlider(null);
                  onParagraphAction(null);
                }}
                className="bg-red-200 text-gray-600 rounded-full p-1 shadow-md focus:outline-none w-6 h-6 flex items-center justify-center"
                aria-label="Close"
              >
                <FiX size={14} />
              </button>
            </>
          ) : (
            ["Settings", "Edit", "Paragraph"]
              .map(renderToggleButton)
              .filter(Boolean)
          )}
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            resetStory();
          }}
          className="flex items-center justify-center bg-white text-red-500 hover:bg-red-50 transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          aria-label="Reset Story"
        >
          <FiRefreshCw size={18} />
          <span className="ml-1 text-xs hidden sm:inline">Reset</span>
        </button>
      </div>
    </div>
  );
};

export default StoryActionButtons;
