import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Tabs from "../components/Tabs";
import StoryList from "../components/StoryList";
import { getPublicStories } from "../api/stories";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import {
  loadAllStoriesFromLocalStorage,
  clearAllStoriesFromLocalStorage,
  deleteStoryFromLocalStorage,
} from "../utils/storageUtils";
import { useStory } from "../context/StoryContext"; // Add this import

const StoriesPage = () => {
  const [activeTab, setActiveTab] = useState("private");
  const [publicStories, setPublicStories] = useState([]);
  const [privateStories, setPrivateStories] = useState({
    published: [],
    unpublished: [],
  });
  const [error, setError] = useState(null);
  const { dispatch } = useStory(); // Add this line to use the context

  useEffect(() => {
    loadStories();
  }, [activeTab]);

  const loadStories = () => {
    setError(null);
    if (activeTab === "public") {
      loadPublicStories();
    } else {
      loadPrivateStories();
    }
  };

  const loadPublicStories = async () => {
    try {
      const stories = await getPublicStories();
      setPublicStories(stories);
    } catch (error) {
      console.error("Failed to load public stories:", error);
      setError("Failed to load public stories. Please try again.");
    }
  };

  const loadPrivateStories = () => {
    try {
      const cachedStories = loadAllStoriesFromLocalStorage();
      setPrivateStories({
        published: cachedStories.filter((story) => story.isPublished),
        unpublished: cachedStories.filter((story) => !story.isPublished),
      });
    } catch (error) {
      console.error("Failed to load private stories from cache:", error);
      setError("Failed to load private stories. Please try again.");
    }
  };

  const handleClearAll = () => {
    if (
      window.confirm("Are you sure you want to clear all unpublished stories?")
    ) {
      clearAllStoriesFromLocalStorage();
      // Reset the state in the context
      dispatch({ type: "RESET_STORY" });
      // Reload private stories to ensure the state is up-to-date
      loadPrivateStories();
    }
  };

  const handleDeleteStory = (storyId) => {
    if (window.confirm("Are you sure you want to delete this story?")) {
      deleteStoryFromLocalStorage(storyId);
      loadPrivateStories();
    }
  };

  const tabs = [
    { id: "private", label: "Private Stories" },
    { id: "public", label: "Public Stories" },
  ];

  const NoStoriesMessage = ({ message }) => (
    <div className="text-gray-500 text-center py-4">{message}</div>
  );

  return (
    <>
      <Helmet>
        <title>Stories | AI Story Generator</title>
        <meta
          name="description"
          content="Browse public and private stories created with our AI Story Generator."
        />
      </Helmet>
      <div className="h-full flex flex-col bg-gray-100 p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-gray-800">Stories</h1>
          <Link
            to="/ai-story-generator?new=true"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlusCircle className="mr-2" />
            Generate New Story
          </Link>
        </div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={loadStories}
            >
              <span className="text-red-500 hover:text-red-800">Retry</span>
            </button>
          </div>
        )}

        {!error && activeTab === "public" ? (
          publicStories.length > 0 ? (
            <StoryList stories={publicStories} type="public" />
          ) : (
            <NoStoriesMessage message="No public stories found." />
          )
        ) : !error ? (
          <>
            <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">
              Published
            </h2>
            {privateStories.published.length > 0 ? (
              <StoryList
                stories={privateStories.published}
                type="private"
                onDelete={handleDeleteStory}
              />
            ) : (
              <NoStoriesMessage message="No published private stories found." />
            )}
            <div className="flex justify-between items-center mt-6 mb-2">
              <h2 className="text-xl font-semibold text-gray-700">
                Unpublished
              </h2>
              {privateStories.unpublished.length > 0 && (
                <button
                  onClick={handleClearAll}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  <FiTrash2 className="mr-2" />
                  Clear All
                </button>
              )}
            </div>
            {privateStories.unpublished.length > 0 ? (
              <StoryList
                stories={privateStories.unpublished}
                type="private"
                onDelete={handleDeleteStory}
              />
            ) : (
              <NoStoriesMessage message="No unpublished private stories found." />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default StoriesPage;
