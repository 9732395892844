import React from "react";
import { Helmet } from "react-helmet";
import AIStoryGenerator from "./views/AIStoryGenerator";
import StoriesPage from "./views/StoriesPage";
import AdminPage from "./views/AdminPage";
import { StoryProvider } from "./context/StoryContext";
import PromptEditor from "./components/PromptEditor";
import MainLayout from "./MainLayout";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <StoryProvider>
      <Router>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <link
            rel="canonical"
            href="https://letmewriteforyou.xyz/ai-story-generator"
          />
        </Helmet>
        <MainLayout>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/ai-story-generator" />
            </Route>
            <Route path="/ai-story-generator" component={AIStoryGenerator} />
            <Route path="/stories" exact component={StoriesPage} />
            <Route path="/admin" exact component={AdminPage} />
            <Route path="/prompts" exact component={PromptEditor} />
          </Switch>
        </MainLayout>
      </Router>
    </StoryProvider>
  );
}

export default App;
