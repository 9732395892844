import React, { useState } from "react";
import { FiEdit2, FiCheck, FiX } from "react-icons/fi";
import ClearableTextarea from "./ClearableTextarea";

const Premise = ({ premise, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPremise, setEditedPremise] = useState(premise);

  const handleEdit = () => {
    setIsEditing(true);
    setEditedPremise(premise);
  };

  const handleSave = () => {
    onUpdate(editedPremise);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedPremise(premise);
  };

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow-sm text-sm p-2">
      <div className="flex items-start">
        <span className="font-semibold mr-2 whitespace-nowrap mt-1">
          Premise:
        </span>
        {isEditing ? (
          <div className="flex-grow">
            <ClearableTextarea
              value={editedPremise}
              onChange={setEditedPremise}
              placeholder="Enter your premise"
              rows={2}
              className="w-full text-sm mb-2"
            />
            <div className="flex justify-end">
              <button
                onClick={handleSave}
                className="text-green-500 hover:text-green-600 mr-2"
                aria-label="Save"
              >
                <FiCheck size={20} />
              </button>
              <button
                onClick={handleCancel}
                className="text-red-500 hover:text-red-600"
                aria-label="Cancel"
              >
                <FiX size={20} />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex-grow flex items-start justify-between">
            <div className="overflow-y-auto max-h-12 mr-2 flex-grow">
              <p className="text-gray-700 break-words">{premise}</p>
            </div>
            <button
              onClick={handleEdit}
              className="text-blue-500 hover:text-blue-600 ml-2 flex-shrink-0"
              aria-label="Edit Premise"
            >
              <FiEdit2 size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Premise;
