import React, { useState, useRef, useEffect, useCallback } from "react";
import EditingParagraph from "./EditingParagraph";
import RewrittenParagraph from "./RewrittenParagraph";
import ParagraphMenu from "./ParagraphMenu";
import ContentGenerator from "./ContentGenerator";
import { useStory } from "../context/StoryContext";
import { FaCheck, FaTimes } from "react-icons/fa";
import SectionBreak from "./SectionBreak";
import { useIsMobile } from "../utils/useIsMobile";
import {
  handleOutsideClick,
  saveCursorPosition,
  restoreCursorPosition,
  convertSentencesToParagraph,
} from "../utils/paragraphUtils";
import { diffChars, diffWordsWithSpace } from "diff";

const Paragraph = ({
  content,
  index,
  isActive,
  setActiveParagraph,
  isCollapsed,
  showSectionBreakButtons,
}) => {
  const isMobile = useIsMobile();
  const { state, dispatch } = useStory();
  const [editedContent, setEditedContent] = useState(content);
  const [isProcessing, setIsProcessing] = useState(false);
  const paragraphRef = useRef(null);
  const originalContent = useRef(content);
  const [rewrittenParagraphs, setRewrittenParagraphs] = useState(null);
  const [mode, setMode] = useState("edit");
  const menuRef = useRef(null);
  const [replacementContent, setReplacementContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isActive) {
      // document.addEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    } else {
      // document.removeEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    }

    return () => {
      // document.removeEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    };
  }, [isActive, setActiveParagraph]);

  useEffect(() => {
    if (!isActive) {
      setEditedContent(content);
      originalContent.current = content;
    }
  }, [content, isActive]);

  useEffect(() => {
    if (replacementContent) {
      console.log(replacementContent);
    }
  }, [replacementContent]);

  const handleInput = (event) => {
    const cursorPosition = saveCursorPosition(event.target);
    const newContent = event.target.innerText;
    const original = originalContent.current;

    // Use diffWordsWithSpace to get the diffs at the word level, including spaces
    const wordDiffs = diffWordsWithSpace(original, newContent);

    let formattedContent = "";
    let originalIndex = 0; // Index to keep track of position in the original text

    wordDiffs.forEach((wordPart) => {
      if (wordPart.added) {
        // Wrap added words in a span
        formattedContent += `<span class="text-blue-600">${escapeHtml(
          wordPart.value
        )}</span>`;
      } else if (wordPart.removed) {
        // Ignore removed words
        originalIndex += wordPart.value.length;
      } else {
        // For unchanged or modified words, check for in-word differences
        const originalSegment = original.substr(
          originalIndex,
          wordPart.value.length
        );
        if (wordPart.value === originalSegment) {
          // Words are identical, append as is
          formattedContent += escapeHtml(wordPart.value);
        } else {
          // Words differ, perform character-level diff within the word
          formattedContent += highlightInWordDifferences(
            originalSegment,
            wordPart.value
          );
        }
        originalIndex += wordPart.value.length;
      }
    });

    console.log(formattedContent);

    setEditedContent(formattedContent);

    setTimeout(() => {
      restoreCursorPosition(paragraphRef.current, cursorPosition);
    }, 0);
  };

  // Function to highlight differences within words
  const highlightInWordDifferences = (originalWord, newWord) => {
    const charDiffs = diffChars(originalWord, newWord);
    let result = "";

    charDiffs.forEach((charPart) => {
      const text = escapeHtml(charPart.value);
      if (charPart.added) {
        result += `<span class="text-blue-600">${text}</span>`;
      } else if (charPart.removed) {
        // Ignore removed characters
      } else {
        result += text;
      }
    });

    return result;
  };

  // Utility function to escape HTML characters to prevent XSS attacks
  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  };

  const handleEdit = useCallback(async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsEditing(true);
  }, []);

  const handleEditComplete = (editedContent) => {
    setEditedContent(editedContent);
    setIsEditing(false);
    // dispatch({
    //   type: "UPDATE_PARAGRAPH",
    //   payload: { index, content: editedContent },
    // });
    // originalContent.current = editedContent;
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handleEdit();
    } else if (event.key === "Tab") {
      event.preventDefault();
      setEditedContent(originalContent.current);
      dispatch({
        type: "UPDATE_PARAGRAPH",
        payload: { index, content: originalContent.current },
      });
    }
  };

  const handleClick = () => {
    if (!isActive) {
      setActiveParagraph(index);
    }
  };

  const handleBlur = () => {
    if (!isProcessing) {
      // setEditedContent(originalContent.current);
    }
  };

  const handleModeChange = useCallback((newMode) => {
    setMode(newMode);
    if (newMode === "delete") {
      handleDelete();
    }
  }, []);

  const handleContentFinalize = async (generatedContent) => {
    if (mode === "rewrite") {
      setIsProcessing(true);
      try {
        const newParagraphs = generatedContent.map((paragraph) =>
          convertSentencesToParagraph(paragraph.sentences)
        );
        // Replace the current paragraph with the first new paragraph
        const updatedContent = newParagraphs[0];
        setEditedContent(updatedContent);
        dispatch({
          type: "UPDATE_PARAGRAPH",
          payload: { index, content: updatedContent },
        });
        originalContent.current = updatedContent;

        // Insert the remaining paragraphs as new ones
        for (let i = 1; i < newParagraphs.length; i++) {
          dispatch({
            type: "INSERT_PARAGRAPH",
            payload: { index: index + i, content: newParagraphs[i] },
          });
        }
      } catch (error) {
        console.error("Error rewriting paragraph:", error);
      } finally {
        setIsProcessing(false);
        setRewrittenParagraphs(null);
      }
    } else if (mode === "insert") {
      generatedContent.forEach((paragraph, i) => {
        dispatch({
          type: "INSERT_PARAGRAPH",
          payload: { index: index + i + 1, content: paragraph },
        });
      });
      setMode("edit");
    } else if (mode === "edit") {
      const newParagraphs = generatedContent.map((paragraph) =>
        convertSentencesToParagraph(paragraph.sentences)
      );
      // Replace the current paragraph with the first new paragraph
      const updatedContent = newParagraphs[0];
      setEditedContent(updatedContent);
      dispatch({
        type: "UPDATE_PARAGRAPH",
        payload: { index, content: updatedContent },
      });
      originalContent.current = updatedContent;
      setRewrittenParagraphs(null);
    }
  };

  const handleContentReject = () => {
    setRewrittenParagraphs(null);
  };

  const handleDelete = () => {
    dispatch({ type: "DELETE_PARAGRAPH", payload: index });
    if (isActive) {
      setActiveParagraph(null);
    }
  };

  const handleInsert = () => {
    setMode("insert");
  };

  const getContext = (mode) => {
    let context = {};
    if (mode == "rewrite") {
      context = {
        paragraph: originalContent.current,
        premise: state.premise,
        previous_paragraph: state.paragraphs[index - 1],
      };
    } else if (mode == "insert") {
      let next_paragraph = "";
      if (index + 1 < state.paragraphs.length) {
        next_paragraph = state.paragraphs[index + 1];
        if (next_paragraph.type === "section_break") {
          next_paragraph = "";
        }
      }
      //get previous three paragraphs
      let previous_paragraphs = state.paragraphs.slice(index - 3, index);
      previous_paragraphs = previous_paragraphs.join("/n/n");
      context = {
        premise: state.premise,
        previous_paragraphs: previous_paragraphs,
        next_paragraph: next_paragraph,
      };
    }
    return context;
  };

  const handleRewriteGeneratedContent = (content) => {
    //Spread operator to create a new array, so that the rewrittenParagraphs state is updated and re-renders
    setRewrittenParagraphs([...content]);
  };

  // Check if the next item is a section break
  const nextItemIsSectionBreak =
    state.paragraphs[index + 1]?.type === "section_break";

  useEffect(() => {
    if (paragraphRef.current) {
      paragraphRef.current.__react_internal_instance$ = {
        return: {
          stateNode: {
            handleModeChange,
          },
        },
      };
    }
  }, [handleModeChange]);

  if (isEditing) {
    return (
      <EditingParagraph
        content={editedContent}
        originalContent={originalContent.current}
        onEditComplete={handleEditComplete}
      />
    );
  }

  if (rewrittenParagraphs && rewrittenParagraphs.length > 0) {
    return (
      <div>
        {rewrittenParagraphs.map((p, index) => (
          <RewrittenParagraph key={index} sentences={p.sentences} />
        ))}
        <div className="flex flex-col sm:flex-row gap-2 mt-4">
          <button
            onClick={() => handleContentFinalize(rewrittenParagraphs)}
            className="flex-1 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
          >
            <FaCheck className="mr-2" /> Accept
          </button>
          <button
            onClick={handleContentReject}
            className="flex-1 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
          >
            <FaTimes className="mr-2" /> Reject
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={paragraphRef}
      data-paragraph-index={index}
      className={`relative px-4 sm:px-6 md:px-8 transition-all duration-300 ease-in-out ${
        isCollapsed ? "h-0 overflow-hidden opacity-0" : "h-auto opacity-100"
      }`}
    >
      <div
        className={`cursor-pointer transition-all duration-300 ease-in-out ${
          isActive ? "ring-2 ring-yellow-400" : ""
        }`}
      >
        <p
          ref={paragraphRef}
          className={`text-base sm:text-lg leading-relaxed text-gray-800 p-2 font-serif transition-all duration-300 ease-in-out ${
            isActive && mode === "edit" && !isProcessing ? "bg-white" : ""
          }`}
          style={{
            textShadow: "0 1px 1px rgba(0,0,0,0.1)",
            textIndent: "1em",
            wordSpacing: "0.05em",
            letterSpacing: "0.01em",
          }}
          onClick={handleClick}
          onBlur={handleBlur}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          contentEditable={isActive && mode === "edit" && !isProcessing}
          suppressContentEditableWarning={true}
          dangerouslySetInnerHTML={{ __html: editedContent }}
        />
        {isActive && mode === "edit" && !isProcessing && (
          <div className="flex justify-end mt-2">
            <button
              onClick={handleEdit}
              className="px-3 py-1 text-sm bg-amber-500 text-white rounded-full transition-colors duration-200 shadow-md flex items-center edit-button"
            >
              <FaCheck className="mr-1 text-white" />
            </button>
          </div>
        )}
      </div>
      {isProcessing && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 rounded-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
        </div>
      )}
      {isActive && (
        <div ref={menuRef} className="mt-4 space-y-4">
          <ParagraphMenu
            mode={mode}
            onModeChange={handleModeChange}
            onDelete={handleDelete}
            onInsert={handleInsert}
          />
          {(mode === "rewrite" || mode === "insert") && (
            <ContentGenerator
              onFinalize={handleContentFinalize}
              mode={mode}
              context={getContext(mode)}
              onGeneratedContent={handleRewriteGeneratedContent}
              title={
                mode === "rewrite" ? "Rewrite paragraph" : "Insert paragraphs"
              }
            />
          )}
        </div>
      )}

      {!nextItemIsSectionBreak && (
        <>
          <div className="md:hidden">
            {showSectionBreakButtons && (
              <SectionBreak index={index} isAfterParagraph={true} />
            )}
          </div>
          <div className="hidden md:block">
            <SectionBreak index={index} isAfterParagraph={true} />
          </div>
        </>
      )}
    </div>
  );
};

export default Paragraph;
